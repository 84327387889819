<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col md="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
              <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                <ValidationProvider name="Organiation" vid="org_id" rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="organiation_id"
                    slot-scope="{ valid, errors }"
                    >
                     <template v-slot:label>
                        {{$t('org_pro.organization')}} <span class="text-danger">*</span>
                      </template>
                    <b-form-select
                      plain
                      v-model="equipment.org_id"
                      :options="organizationList"
                      id="organiation_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Equipment Name (En)" vid="eq_type_name" rules="required">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="equipment_type"
                    slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                    {{$t('irrigation_config.equipment_type')}} <span class="text-danger">*</span>
                  </template>
                    <b-form-input
                      id="equipment_type"
                      v-model="equipment.eq_type_name"
                      :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Equipment Name (Bn)" vid="eq_type_name_bn" rules="required">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="equipment_bn"
                    slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                    {{$t('irrigation_config.equipment_type_bn')}} <span class="text-danger">*</span>
                  </template>
                    <b-form-input
                      id="equipment_bn"
                      v-model="equipment.eq_type_name_bn"
                      :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <div class="row">
                  <div class="col-sm-3"></div>
                  <div class="col text-right">
                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                    &nbsp;
                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                  </div>
                </div>
              </b-form>
            </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { equipmentStore, equipmentUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getequipmentData()
      this.equipment = tmp
    }
    this.getOrganizationlist()
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      totalPost: 0,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      equipment: {
        eq_type_name: '',
        eq_type_name_bn: '',
        org_id: 0
      },
      organizationList: []
    }
  },
  computed: {

  },
  watch: {

  },
  methods: {
    getequipmentData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.equipment.id) {
        result = await RestApi.putData(irriSchemeServiceBaseUrl, `${equipmentUpdate}/${this.id}`, this.equipment)
      } else {
        result = await RestApi.postData(irriSchemeServiceBaseUrl, equipmentStore, this.equipment)
      }

      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)
      if (result.success) {
        this.$store.dispatch('IrriConfig/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    },
    getOrganizationlist () {
      const orgList = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
        this.organizationList = orgList.map(obj => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
          } else {
            return { value: obj.value, text: obj.text }
          }
        })
    }
  }
}
</script>
